import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { useModal } from "mui-modal-provider";
import { MouseEvent, KeyboardEvent, ReactElement, ReactNode, VFC } from "react";
import { useCallbackSafeRef } from "../hooks/useCallbackSafeRef";
import { AsyncButton } from "./forms/AsyncButton";

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      green: {
        color: "#8DDFAB",
      },
      red: {
        color: "#FF9797",
      },
      dialogPaper: {
        borderRadius: theme.shape.borderRadius * 2,
        padding: theme.spacing(2, 1, 3),
        color: theme.palette.getContrastText(theme.colors.dialog),
        backgroundColor: theme.colors.dialog,
      },
      dialogContent: {
        color: theme.palette.getContrastText(theme.colors.dialog),
        paddingTop: 0,
        "&:first-child": {
          paddingTop: theme.spacing(1.5),
        },
      },
      dialogTitle: {
        ...theme.typography.h3,
        color: theme.palette.getContrastText(theme.colors.dialog),
      },
      dialogText: {
        color: theme.palette.getContrastText(theme.colors.dialog),
      },
      whiteBtn: {
        color: theme.palette.common.white,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: "auto",
        },
      },
      confirmDisabled: {
        "&.Mui-disabled": {
          color: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          opacity: 0.25,
        },
      },
      primaryBtn: {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          minWidth: 120,
          width: "auto",
        },
      },
    }),
  { classNamePrefix: "ConfirmDialog" }
);

export type ConfirmDialogJSSClassKey = keyof ReturnType<typeof useStyles>;
export type ConfirmDialogCloseHandler = (e?: MouseEvent | KeyboardEvent) => unknown;

export type ConfirmDialogProps = {
  title?: ReactNode;
  message?: ReactNode | ((close: () => void) => void);
  form?: ReactElement;
  confirmLabel?: string;
  declineLabel?: string;
  open?: boolean;
  confirmDisabled?: boolean;
  className?: string;
  classes?: Partial<ClassNameMap<ConfirmDialogJSSClassKey>>;
  DialogProps?: Omit<DialogProps, "open" | "onClose" | "aria-labelledby" | "aria-describedby">;
  onConfirm: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => Promise<unknown> | unknown;
  onCancel?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>) => unknown;
  onClose?: ConfirmDialogCloseHandler;
  onSuccess?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>, value: unknown) => unknown;
  onError?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLLIElement>, error: Error) => unknown;
};

export const ConfirmDialog: VFC<ConfirmDialogProps> = ({
  className,
  title,
  message,
  form,
  confirmLabel = "Confirm",
  declineLabel = "Cancel",
  open = false,
  confirmDisabled,
  DialogProps,
  onConfirm,
  onCancel,
  onClose,
  onSuccess,
  onError,
}) => {
  const classes = useStyles();

  const handleClose = useCallbackSafeRef((e?: unknown) =>
    onClose?.((e instanceof KeyboardEvent || e instanceof MouseEvent ? e : undefined) as undefined)
  );

  const handleCancel = useCallbackSafeRef((e: MouseEvent<HTMLButtonElement>) => {
    return onCancel?.(e);
  });

  const handleConfirm = useCallbackSafeRef(async (e: MouseEvent<HTMLButtonElement>) => {
    return await onConfirm(e);
  });

  const handleSuccess = useCallbackSafeRef((e: MouseEvent<HTMLButtonElement>, value: unknown) => {
    return onSuccess?.(e, value);
  });

  const handleError = useCallbackSafeRef((e: MouseEvent<HTMLButtonElement>, error: Error) => {
    return onError?.(e, error);
  });

  message = typeof message === "function" ? message(handleClose) : message;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      classes={{
        paper: classes.dialogPaper,
      }}
      className={clsx(className, classes.root)}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      {...DialogProps}
    >
      {!!title && (
        <DialogTitle
          disableTypography
          id="confirm-dialog-title"
          classes={{
            root: classes.dialogTitle,
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        classes={{
          root: classes.dialogContent,
        }}
      >
        {!!message && (
          <DialogContentText id="confirm-dialog-description" component="div" className={classes.dialogText}>
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      {!!form && form}
      <DialogActions>
        <Button className={classes.primaryBtn} variant="contained" color="primary" autoFocus onClick={handleCancel}>
          {declineLabel}
        </Button>
        <AsyncButton
          className={clsx(classes.whiteBtn, {
            [classes.confirmDisabled]: !!confirmDisabled,
          })}
          variant="outlined"
          color="inherit"
          disabled={!!confirmDisabled}
          onClick={handleConfirm}
          onSuccess={handleSuccess}
          onError={handleError}
        >
          {confirmLabel}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

export type UseConfirmDialogProps = Omit<ConfirmDialogProps, "open">;

export const useConfirmDialog = <P extends Partial<UseConfirmDialogProps>>(baseProps: P = {} as P) => {
  const { showModal } = useModal({ disableAutoDestroy: true });

  return useCallbackSafeRef((props?: Partial<UseConfirmDialogProps> & Omit<UseConfirmDialogProps, keyof P>) => {
    const { onClose, onCancel, onConfirm, ...combinedProps } = {
      ...baseProps,
      ...props,
    } as UseConfirmDialogProps;

    const { hide, destroy } = showModal(ConfirmDialog, {
      onClose: (e) => {
        onClose?.(e);
        hide();
        destroy();
      },
      onCancel: (e) => {
        onCancel?.(e);
        hide();
        destroy();
      },
      onConfirm: (e) => {
        onConfirm?.(e);
        hide();
        destroy();
      },
      ...combinedProps,
    });
  });
};
